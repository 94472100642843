<template>
  <div class="slk-input">
    <b-form-input v-model="formatTime" :state="error" @focus="isShowAction=true" @blur="blur"/>
    <div class="slk-input__actions d-flex justify-content-end" v-if="isShowAction">
      <b-button
        variant="outline-secondary"
        class="btn-icon"
        @click="validation"
      >
        <feather-icon icon="CheckIcon" />
      </b-button>
      <b-button
        variant="outline-secondary"
        class="btn-icon"
      >
        <feather-icon icon="XIcon" />
      </b-button>
    </div>
    
  </div>
  
</template>

<script>
import { BFormInput, BButton } from 'bootstrap-vue'
import { toSecondsFromFormat, toFormatFromSec1, toFormatFromMin1 } from "@/helpers"

export default {
  name: "TimeInput",
  props: {
		  value: [String, Number],
  },
  components: {
      BFormInput,
		  BButton
  },
  data: () => ({
      error: null,
      isShowAction: false,
		  formatTime: 0,
		  toSecondsFromFormat,
		  toFormatFromSec1,
		  toFormatFromMin1
  }),
  methods: {
		  validation() {
          let isInt = !isNaN(this.formatTime);
          if(!isInt) {
		          let isCorrect =
                  this.formatTime.search(/^\b\d{1,3}w$/) > -1 ||
                  this.formatTime.search(/^\b\d{1,3}d$/) > -1 ||
                  this.formatTime.search(/^\b\d{1,3}h$/) > -1 ||
				          this.formatTime.search(/^\b\d{1,3}m$/) > -1 ||
                  this.formatTime.search(/^\b\d{1,3}h\b(\s\b\d{1,3}m\b)?$/) > -1 ||
                  this.formatTime.search(/^\b\d{1,3}d\b(\s\b\d{1,3}h\b)?(\s\b\d{1,3}m\b)?$/) > -1 ||
                  this.formatTime.search(/^\b\d{1,3}w\b(\s\b\d{1,3}d\b)?(\s\b\d{1,3}h\b)?(\s\b\d{1,3}m\b)?$/) > -1;
                this.error = isCorrect;
            
                if(!isCorrect) {
                		this.formatTime = 0;
                } else {
		                let sec = this.toSecondsFromFormat(this.formatTime);
		                this.formatTime = this.toFormatFromSec1(sec);
		                this.$emit('input', sec)
                    this.$emit('callback')
                }
          } else {
		          this.$emit('input', this.formatTime * 60);
		          this.formatTime = this.toFormatFromMin1(this.formatTime);
          }
      },
		  blur() {
		  		this.isShowAction = false;
		  		this.validation();
      },
  },
  mounted() {
		  this.formatTime = this.toFormatFromSec1(this.value);
  },
}
</script>

<style scoped lang="scss">
.slk-input {
  position: relative;
  z-index: 10;
  &__actions {
      position: absolute;
      width: 100%;
      padding-top: 5px;
      button {
        background: #fff;
        margin-left: 5px;
        &:hover {
          background: #ebebebfa !important;
        }
      }
   }
}
</style>
